


































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import { Validations } from 'vuelidate-property-decorators'
import { validationMixin } from 'vuelidate'
import { required, minValue } from 'vuelidate/lib/validators'
import TextInput from '@/app/ui/components/TextInput/index.vue'
import DropdownSelect from '@/app/ui/components/DropdownSelect/index.vue'
import ImageUpload from '@/app/ui/components/ImageUpload/index.vue'
import Button from '@/app/ui/components/Button/index.vue'
import LoadingOverlay from '@/app/ui/components/LoadingOverlay/index.vue'
import SearchIcon from '@/app/ui/assets/search_icon.vue'
import ManageCourierController from '@/app/ui/controllers/ManageCourierController'
import ManualAdjustController from '@/app/ui/controllers/ManualAdjustmentBalanceController'
import ModalConfirm from '../../components/Modals/ModalConfirm/index.vue'
import ModalSuccess from '../../components/Modals/ModalSuccess/index.vue'
import { EventBus, EventBusConstants, Utils } from '@/app/infrastructures/misc'
import { ManageCourierAdjustSaldo } from '@/domain/entities/ManageCourier'

interface Options {
  label: string
  value: string
}

@Component({
  mixins: [validationMixin],
  components: {
    TextInput,
    DropdownSelect,
    ImageUpload,
    Button,
    ModalConfirm,
    ModalSuccess,
    LoadingOverlay,
    SearchIcon
  },
})

export default class CreateManualAdjustSaldo extends Vue {
  maController = ManualAdjustController
  mcController = ManageCourierController
  MAX_SIZE = 5000
  modalConfirmVisible = false
  modalSuccessVisible = false
  isValidate = false

  typeOptions = [
    { label: 'Rekonsiliasi Penambahan Saldo', value: 'BAL_ADD_RCC' },
    { label: 'Rekonsiliasi Pengurangan Saldo', value: 'BAL_RDC_RCC' },
  ]

  courierList: Array<{ label: string, value: number }> = []

  form = {
    name: this.courierList[0],
    transactionType: this.typeOptions[-1],
    amount: 0,
    image: <File[] | Array<undefined>>[],
    notes: ''
  }

  parameters = {
    page: 1,
    perPage: 5,
    q: '',
    sortBy: 'ASC',
    driverType: 'KVP',
    courierStatus: ''
  }


  @Validations()
  validations(): Record<string, unknown> {
    return {
      form: {
        name: { required },
        transactionType: { required },
        amount: { required, minValue: minValue(0) },
      }
    }
  }

  created(): void {
    EventBus.$on(EventBusConstants.ADD_MANUAL_ADJUSTMENT_BALANCE_SUCCESS, (response: boolean) => {
      if (response) {
        this.modalSuccessVisible = true
      }
    })

    this.fetchCourier()
  }

  get courierParams(): Record<string, string | number> {
    let payload: Record<string, string | number> = { ...this.parameters }
    return payload;
  }

  private fetchCourier(reset?: boolean) {
    if (reset) this.parameters.page = 1
    this.mcController.getManageCourierListAdjustSaldo(this.courierParams)
  }

  private onFilterList = Utils.debounce(() => {
    this.fetchCourier(true)
  }, 400)

  private onDropdownSearch = (query: string) => {
    this.parameters.q = query
    this.onFilterList()
  }

  private onFileChange(file: File) {
    if (file) {
      (<File[]>this.form.image).push(<File>file)
    }
  }

  private onRemoveImage(index: number) {
    this.form.image.splice(index, 1)
  }

  private onCloseSuccessModal() {
    this.modalSuccessVisible = false
    this.$router.push('/payroll/manual-adjust-saldo')
  }

  private onSubmit() {
    this.isValidate = true
    this.modalConfirmVisible = false

    if (!this.$v.form.$invalid) {
      let meta: Array<string> = []

      const uploadImages = new Promise<void>((resolve, reject) => {
        if (this.form.image.length !== 0) {
          this.form.image.forEach(async (value?: File) => {
            if (value) {
              const url = await this.onUploadImage(value)

              if (url) {
                meta.push(url)
              }
            }

            if (meta.length === this.form.image.length) {
              resolve()
            }
          })
        } else {
          resolve()
        }
      });

      uploadImages.then(() => {
        const payload = {
          courierId: this.form.name.value,
          amount: this.form.amount,
          transactionType: this.form.transactionType.value,
          notes: this.form.notes,
          meta: meta
        }

        this.maController.create(payload)
      })
    } else {
      Vue.notify({
        title: 'Create Manual Adjustment Saldo Failed',
        text: 'Please check every invalid form',
        type: 'error',
        duration: 5000,
      })
    }
  }

  private async onUploadImage(img: File | Blob) {
    const url = await this.maController.uploadImage(img)
    return url
  }

  @Watch('mcController.manageCourierAdjustSaldoData')
  setManageCourierData(data: Array<ManageCourierAdjustSaldo>): void {
    let result: Array<{ label: string, value: number }> = []

    data.forEach((courier: ManageCourierAdjustSaldo) => {
      const label = `[${courier.courierId}] ${courier.fullName} (${courier.announcementCourierType}) ${courier.phoneNumber}`
      const value = courier.courierId

      if (label && value) {
        result.push({
          label: label,
          value: value
        })
      }
    })

    this.courierList = result
  }

  beforeDestroy(): void {
    EventBus.$off(EventBusConstants.ADD_MANUAL_ADJUSTMENT_BALANCE_SUCCESS)
  }
}
