import {
  CreateManualAdjustmentBalanceRequestInterface,
  UploadBulkyManualAdjustmentBalanceRequestInterface,
  UploadManualAdjustmentBalanceRequestInterface,
} from '@/data/payload/contracts/ManualAdjustmentBalanceRequest'

export class CreateManualAdjustmentBalanceApiRequest
  implements CreateManualAdjustmentBalanceRequestInterface {
  public courier_id?: number
  public amount?: number
  public transaction_type?: string
  public notes?: string
  public meta?: Array<string>

  constructor(
    courierId?: number,
    amount?: number,
    transactionType?: string,
    notes?: string,
    meta?: Array<string>,
  ) {
    this.courier_id = courierId,
    this.amount = amount,
    this.transaction_type = transactionType,
    this.notes = notes,
    this.meta = meta
  }

  public toPayload(): string {
    const data = {
      courier_id: this.courier_id,
      amount: this.amount,
      transaction_type: this.transaction_type,
      notes: this.notes,
      meta: this.meta,
    }
    return JSON.stringify({
      ...data,
    })
  }
}

export class UploadManualAdjustmentBalanceRequest
  implements UploadManualAdjustmentBalanceRequestInterface {
  public images?: Blob | File | null

  constructor(images?: Blob | File | null) {
    this.images = images
  }

  public toPayload(): FormData {
    const form = new FormData()
    form.append('images', this.images as Blob)
    return form
  }
}

export class UploadBulkyManualAdjustmentBalanceRequest
  implements UploadBulkyManualAdjustmentBalanceRequestInterface {
  public file?: File

  constructor(file?: File) {
    this.file = file
  }

  public toPayload(): FormData {
    const form = new FormData()
    form.append('file', this.file as File)
    return form
  }
}
