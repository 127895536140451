import { container } from 'tsyringe'
import Vue from 'vue'
import {
  VuexModule,
  Module,
  Mutation,
  Action,
  getModule,
} from 'vuex-module-decorators'
import store from '@/app/ui/store'
import { ManualAdjustmentBalancePresenter } from '@/app/ui/presenters/ManualAdjustmentBalancePresenter'
import { EventBus, EventBusConstants, Utils } from '@/app/infrastructures/misc'
import {
  ManualAdjustmentBalance,
  ManualAdjustmentBalances,
  ManualAdjustmentUploadHistories,
  ManualAdjustmentUploadHistory,
  UploadBulkData
} from '@/domain/entities/ManualAdjustmentBalance'
import { Pagination } from '@/domain/entities/Pagination'
import { CreateManualAdjustmentBalanceApiRequest,
  UploadBulkyManualAdjustmentBalanceRequest,
  UploadManualAdjustmentBalanceRequest }
from '@/data/payload/api/ManualAdjustmentBalanceRequest'
import { EnumStatusUpload } from '@/app/infrastructures/misc/Constants/manualAdjustSaldo'

interface ManualAdjustPayload {
  courierId: number
  amount: number
  transactionType: string
  notes: string
  meta: Array<string>
}

export interface PayrollManualAdjustState {
  isLoading: boolean
  isLoadingHistory: boolean
  isLoadingExport: boolean
  isLoadingGetUploadBulkData: boolean
  isLoadingSubmitBulk: boolean
  isUploading: boolean
  statusUpload: EnumStatusUpload
  filenameBulkUpload: string
  dataPayrollManualAdjustmentList: ManualAdjustmentBalance[]
  dataPayrollManualAdjust: ManualAdjustmentBalance
  dataManualAdjustmentUploadHistoryList: ManualAdjustmentUploadHistory[]
  dataUploadBulky: UploadBulkData
  paginationData: Pagination
  uploadHistoryPaginationData: Pagination
  uploadBulkPaginationData: Pagination
  errUploadBulky: string
  errSubmitBulky: string
}

@Module({
  namespaced: true,
  dynamic: true,
  store,
  name: 'manual-adjustment-balance',
})
class ManualAdjustmentBalanceController extends VuexModule implements PayrollManualAdjustState {
  private presenter: ManualAdjustmentBalancePresenter = container.resolve(ManualAdjustmentBalancePresenter)
  public isLoading = false
  public isLoadingHistory = false
  public isLoadingExport = false
  public isLoadingDownload = false
  public isLoadingGetUploadBulkData = false
  public isLoadingSubmitBulk = false
  public isUploading = false
  public statusUpload = EnumStatusUpload.START
  public filenameBulkUpload = ''
  public dataPayrollManualAdjustmentList = [new ManualAdjustmentBalance()]
  public dataPayrollManualAdjust = new ManualAdjustmentBalance()
  public dataManualAdjustmentUploadHistoryList = [new ManualAdjustmentUploadHistory()]
  public dataUploadBulky = new UploadBulkData()
  public paginationData = new Pagination()
  public uploadHistoryPaginationData = new Pagination()
  public uploadBulkPaginationData = new Pagination()
  public errUploadBulky = ''
  public errSubmitBulky = ''

  @Action({ rawError: true })
  public getAll(params: Record<string, string | number>) {
    this.setLoading(true)

    const formattedParams = Utils.toInstance(
      new Map(),
      JSON.stringify(params),
      'snake_case'
    )

    this.presenter
      .getAll(formattedParams)
      .then(res => {
        if (res.data) this.setDataPayrollManualAdjustmentList(res)
      })
      .catch(error => {
        Vue.notify({
          title: 'Fetch Manual Adjust List Failed',
          text:
            [400, 422].includes(error.status)
              ? error.error.message.en
              : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  @Action({ rawError: true })
  public getListUploadHistory(params: Record<string, string | number>) {
    this.setLoadingHistory(true)

    const formattedParams = Utils.toInstance(
      new Map(),
      JSON.stringify(params),
      'snake_case'
    )

    this.presenter
      .getUploadHistoryList(formattedParams)
      .then(res => {
        if (res.data) this.setDataManualAdjustmenUploadHistorytList(res)
      })
      .catch(error => {
        Vue.notify({
          title: 'Fetch Manual Adjust Upload History List Failed',
          text:
            [400, 422].includes(error.status)
              ? error.error.message.en
              : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setLoadingHistory(false)
      })
  }

  @Action({ rawError: true })
  public create(payload: ManualAdjustPayload) {
    this.setLoading(true)
    this.presenter
      .create(new CreateManualAdjustmentBalanceApiRequest(
        payload.courierId,
        payload.amount,
        payload.transactionType,
        payload.notes,
        payload.meta,
      ))
      .then(res => {
        EventBus.$emit(EventBusConstants.ADD_MANUAL_ADJUSTMENT_BALANCE_SUCCESS, res)
      })
      .catch(error => {
        Vue.notify({
          title: 'Create Manual Adjustment Balance Failed',
          text:
            [400, 422].includes(error.status)
              ? error.error.message.en
              : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
        EventBus.$emit(EventBusConstants.ADD_MANUAL_ADJUSTMENT_BALANCE_SUCCESS, false)
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  @Action({ rawError: true })
  public exportManualAdjustmentBalance(params: Record<string, string | number>) {
    this.setLoadingExport(true)

    const formattedParams = Utils.toInstance(
      new Map(),
      JSON.stringify(params),
      'snake_case'
    )

    this.presenter
      .export(formattedParams)
      .then((res) => {
        window.open(res)
      })
      .catch(error => {
        Vue.notify({
          title: 'Export Manual Adjustment Failed',
          text:
            [400, 422].includes(error.status)
              ? error.error.message.en
              : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setLoadingExport(false)
      })
  }

  @Action({ rawError: true })
  public async uploadImage(image: File | Blob) {
    this.setUploading(true)
    return this.presenter
      .uploadImage(new UploadManualAdjustmentBalanceRequest(image))
      .then((url) => {
        return url
      })
      .catch(error => {
        Vue.notify({
          title: 'Upload Image Failed',
          text:
            [400, 422].includes(error.status)
              ? error.error.message.en
              : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setUploading(false)
      })
  }

  @Action({ rawError: true })
  public uploadBulky(payload: { file: File }) {
    this.setFilenameBulkUpload(payload.file.name)
    this.setStatusUpload(EnumStatusUpload.PROCESSING)
    this.presenter
      .uploadBulky(new UploadBulkyManualAdjustmentBalanceRequest(payload.file))
      .then((res) => {
        if (res) {
          this.setStatusUpload(EnumStatusUpload.FAILED)
        } else {
          this.setStatusUpload(EnumStatusUpload.COMPLETE)
        }
        EventBus.$emit(EventBusConstants.UPLOAD_BULKY_MANUAL_ADJUSTMENT_BALANCE_SUCCESS, res)
      })
      .catch(error => {
        EventBus.$emit(EventBusConstants.UPLOAD_BULKY_MANUAL_ADJUSTMENT_BALANCE_FAILED, error)
        this.setStatusUpload(EnumStatusUpload.FAILED)
        Vue.notify({
          title: 'Upload Bulky Failed',
          text:
            [400, 422].includes(error.status)
              ? error.error.message.en
              : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
  }

  @Action({ rawError: true })
  public downloadTemplate() {
    this.setLoadingDownload(true)

    this.presenter
      .downloadTemplate()
      .then((res) => {
        window.open(res)
      })
      .catch(error => {
        Vue.notify({
          title: 'Download Template Manual Adjustment Saldo Failed',
          text:
            [400, 422].includes(error.status)
              ? error.error.message.en
              : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setLoadingDownload(false)
      })
  }

  @Action({ rawError: true })
  public uploadBulkyNew(payload: { file: File }) {
    this.setFilenameBulkUpload(payload.file.name)
    this.setStatusUpload(EnumStatusUpload.PROCESSING)
    this.presenter
      .uploadBulkyNew(
        new UploadBulkyManualAdjustmentBalanceRequest(payload.file)
      )
      .then(res => {
        this.setDataUploadBulky(res)
        this.setStatusUpload(EnumStatusUpload.COMPLETE)
        this.setErrUploadBulky(EventBusConstants.UPLOAD_BULKY_MANUAL_ADJUSTMENT_BALANCE)
      })
      .catch(error => {
        this.setErrUploadBulky(error.error.message.en)
        this.setStatusUpload(EnumStatusUpload.FAILED)
        if (!this.errUploadBulky.includes('is already uploaded')) {
          Vue.notify({
            title: 'Upload Bulky Failed',
            text: [400, 422].includes(error.status)
              ? error.error.message.en
              : 'Something wrong',
            type: 'error',
            duration: 5000,
          })
        }
      })
  }

  @Action({ rawError: true })
  public getListUploadBulk(params: Record<string, string | number>) {
    this.setLoadingGetUploadBulkData(true)

    const formattedParams = Utils.toInstance(
      new Map(),
      JSON.stringify(params),
      'snake_case'
    )

    this.presenter
      .getUploadBulkData(formattedParams)
      .then(res => {
        this.setDataUploadBulky(res)
      })
      .catch(error => {
        Vue.notify({
          title: 'Fetch Upload Bulk Data List Failed',
          text:
            [400, 422].includes(error.status)
              ? error.error.message.en
              : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setLoadingGetUploadBulkData(false)
      })
  }

  @Action({ rawError: true })
  public submitBulk() {
    this.setLoadingSubmitBulk(true)

    this.presenter
      .submitBulk()
      .then(res => {
        this.setErrSubmitBulky(EventBusConstants.SUBMIT_BULKY_MANUAL_ADJUSTMENT_BALANCE)
      })
      .catch(error => {
        this.setErrSubmitBulky(error.error.message.en)
        Vue.notify({
          title: 'Submit Bulk Failed',
          text:
            [400, 422].includes(error.status)
              ? error.error.message.en
              : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setLoadingSubmitBulk(false)
      })
  }

  @Mutation
  private setLoading(bool: boolean) {
    this.isLoading = bool
  }

  @Mutation
  private setLoadingHistory(bool: boolean) {
    this.isLoadingHistory = bool
  }

  @Mutation
  private setLoadingGetUploadBulkData(bool: boolean) {
    this.isLoadingGetUploadBulkData = bool
  }

  @Mutation
  public setLoadingSubmitBulk(bool: boolean) {
    this.isLoadingSubmitBulk = bool
  }

  @Mutation
  private setUploading(bool: boolean) {
    this.isUploading = bool
  }

  @Mutation
  public setStatusUpload(status: EnumStatusUpload) {
    this.statusUpload = status
  }

  @Mutation
  private setLoadingExport(bool: boolean) {
    this.isLoadingExport = bool
  }

  @Mutation
  private setLoadingDownload(bool: boolean) {
    this.isLoadingDownload = bool
  }

  @Mutation
  public setFilenameBulkUpload(filename: string) {
    this.filenameBulkUpload = filename
  }

  @Mutation
  private setDataPayrollManualAdjustmentList(list: ManualAdjustmentBalances) {
    this.dataPayrollManualAdjustmentList = <ManualAdjustmentBalance[]>list.data
    this.paginationData = <Pagination>list.pagination
  }

  @Mutation
  private setDataManualAdjustmenUploadHistorytList(list: ManualAdjustmentUploadHistories) {
    this.dataManualAdjustmentUploadHistoryList = <ManualAdjustmentUploadHistory[]>list.data
    this.uploadHistoryPaginationData = <Pagination>list.pagination
  }

  @Mutation
  private setDataUploadBulky(data: UploadBulkData) {
    this.dataUploadBulky = <UploadBulkData>data
  }

  @Mutation
  public setDataUploadPaginationBulky(pagination: Pagination) {
    this.uploadBulkPaginationData = <Pagination>pagination
  }

  @Mutation
  public setErrUploadBulky(err: string) {
    this.errUploadBulky = err
  }

  @Mutation
  public setErrSubmitBulky(err: string) {
    this.errSubmitBulky = err
  }
}

export default getModule(ManualAdjustmentBalanceController)
